



























import { Component, Vue, Prop } from 'vue-property-decorator'

import File from '@/components/files/File.vue'
import Dots from '@/components/widgets/DotDotDot.vue'
import UserImage from '@/components/user/UserImage.vue'
import FileWrapper from '@/components/files/FileWrapper.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import InformationPatchForm from '@/components/implementation/information/InformationPatchForm.vue'

import { InformationResource } from '@/models/implementations/information/InformationResource'

@Component({
  components: {
    File,
    Dots,
    UserImage,
    FileWrapper,
    SmallLoader,
    InformationPatchForm,
  },
})
export default class QuestionAnswerForm extends Vue {
  @Prop()
  private information!: InformationResource

  private isEditing: boolean = false
  private isDeleting: boolean = false

  private get CanDoAnyAction(): boolean {
    return this.canEditInformation || this.canDeleteInformation
  }

  private get canEditInformation(): boolean {
    return this.information.canPerformAction('can_edit_information')
  }

  private get canDeleteInformation(): boolean {
    return this.information.canPerformAction('can_edit_information')
  }

  private get files(): Array<{ name: string; url?: string }> {
    return this.information.files ?? []
  }

  private openEditInformation() {
    this.isEditing = true
  }

  private closeEditInformation() {
    this.isEditing = false
  }

  private informationUpdated() {
    this.isEditing = false
  }

  private async deleteInformation(): Promise<void> {
    this.isDeleting = true
    try {
      await this.information.delete()
      this.$emit('deleted', this.information)
    } finally {
      this.isDeleting = false
    }
  }
}
