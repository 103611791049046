














import { Component, Vue, Prop } from 'vue-property-decorator'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Information from '@/components/implementation/information/Information.vue'
import InformationCreateForm from '@/components/implementation/information/InformationCreateForm.vue'

import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import { InformationResource } from '@/models/implementations/information/InformationResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'

@Component({
  components: {
    Information,
    SmallLoader,
    InformationCreateForm
  }
})
export default class ImplementationInformation extends Vue {

  @Prop()
  private readonly proposal!: ImplementationProposalResource

  @Prop()
  private readonly implementation!: ImplementationResource

  @Prop()
  private information!: InformationResource[]

  @Prop()
  private fetchingInformation!: boolean

  private isAddingInformation: boolean = false

  private get canAddInformation(): boolean {
    return this.implementation.canPerformAction(`can_add_information`)
  }

  private openAddInformationScreen(): void {
    this.isAddingInformation = true
  }

  private closeAddInformationScreen(): void {
    this.isAddingInformation = false
  }

  private informationCreated(information: InformationResource): void {
    this.isAddingInformation = false
    this.$emit('created', information)
  }

  private informationDeleted(information: InformationResource): void {
    this.$emit('deleted', information)
  }

}
