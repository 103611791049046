import { InformationResource } from '@/models/implementations/information/InformationResource'

export class InformationPatchRequest {
  public message: string = ''
  public files: File[] = []
  public existing_files: Array<{ name: string, url: string }> = []

  constructor(information: InformationResource) {
    this.message = information.message
    this.existing_files = information.files ? [...information.files] : []
  }

  public get isValid(): boolean {
    return !!this.message
  }

  public get requestBody(): FormData {
    const formData = new FormData()
    formData.append('message', this.message)
    for (const [index, file] of this.existing_files.entries()) {
      formData.append(`existing_files[${index}][name]`, file.name)
      formData.append(`existing_files[${index}][url]`, file.url)
    }
    for (const file of this.files) {
      formData.append('files[]', file)
    }
    return formData
  }
}
