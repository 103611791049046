export class InformationCreateRequest {
  public message: string = ''
  public files: File[] = []

  public get isValid(): boolean {
    return !!this.message
  }

  public get requestBody(): FormData {
    const formData = new FormData()
    formData.append('message', this.message)
    for (const file of this.files) {
      formData.append('files[]', file)
    }
    return formData
  }
}
