













import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalInformation from './partials/Information.vue'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { InformationResource } from '@/models/implementations/information/InformationResource'

@Component({
  components: {
    SmallLoader,
    ProposalInformation
  }
})
export default class ImplementationProposal extends Vue {

  @Prop()
  private proposal?: ImplementationProposalResource

  @Prop()
  private implementation?: ImplementationResource

  private currentPage: string = 'information'
  private information: InformationResource[] = []
  private fetchingInformation: boolean = false

  @Watch('proposal.id')
  private onProposalChange(val: number | undefined, oldVal: number | undefined) {
    if (val !== oldVal) {
      this.getInformation()
    }
  }

  private created() {
    this.getInformation()
  }

  private async getInformation(): Promise<void> {
    this.information = []
    this.fetchingInformation = true
    try {
      const data = await this.proposal?.getInformation()
      if (data) {
        this.information = data.data
      }
    } finally {
      this.fetchingInformation = false
    }
  }

  private informationDeleted(information: InformationResource): void {
    const index = this.information.findIndex(({ id }) => id === information.id)
    if (index > -1) {
      this.information.splice(index, 1)
    }
  }


  private goToPage(page: string): void {
    this.currentPage = page
  }

  private informationCreated(information: InformationResource): void {
    this.information.push(information)

  }

}
