
























import { Component, Vue, Prop } from 'vue-property-decorator'

import File from '@/components/files/File.vue'
import Textarea from '@/components/inputs/Textarea.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import UserImage from '@/components/user/UserImage.vue'
import FileWrapper from '@/components/files/FileWrapper.vue'
import FileUploadButton from '@/components/inputs/FileUploadButton.vue'

import { InformationCreateRequest } from '@/requests/implementations/information/InformationCreateRequest'
import { InformationPatchRequest } from '@/requests/implementations/information/InformationPatchRequest'
import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import { InformationResource } from '@/models/implementations/information/InformationResource'

@Component({
  components: {
    File,
    Textarea,
    UserImage,
    SmallLoader,
    FileWrapper,
    FileUploadButton
  },
})
export default class QuestionAnswerForm extends Vue {
  @Prop()
  private proposal!: ImplementationProposalResource

  private fileTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'text/csv',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  private form: InformationCreateRequest = new InformationCreateRequest()
  private errors: ErrorResponse = {}

  private isSubmitting: boolean = false

  private get files(): Array<{ name: string; url?: string }> {
    return this.form.files.map((file) => {
      return {
        name: file.name,
      }
    })
  }

  private addFiles(files: FileList): void {
    if (!this.form) return

    if (files) {
      Array.from(files).forEach((file) => {
        if (this.form) {
          this.form.files.push(file)
        }
      })
    }
  }

  private deleteUploadingFile(index: number) {
    this.form.files.splice(index, 1)
  }

  private async createInformation(): Promise<void> {
    if (!this.form?.isValid) return

    this.isSubmitting = true
    try {
      const { data } = await this.proposal.createInformation(this.form)
      this.$emit('submitted', data)
    } catch (e) {
      console.error(e)
      if (e.errors) {
        this.errors = e.errors
      }
    } finally {
      this.isSubmitting = false
    }
  }

}
